/* Regular*/
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  font-style: normal;
  src: url('../assets/fonts/Inter-Regular.woff') format('woff'),
    url('../assets/fonts/Inter-Regular.woff2') format('woff2');
}

/* Medium */
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  font-style: normal;
  src: url('../assets/fonts/Inter-Medium.woff') format('woff'),
    url('../assets/fonts/Inter-Medium.woff2') format('woff2');
}

/* Semibold */
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  font-style: normal;
  src: url('../assets/fonts/Inter-SemiBold.woff') format('woff'),
    url('../assets/fonts/Inter-SemiBold.woff2') format('woff2');
}

/* Bold */
@font-face {
  font-family: 'Inter';
  font-weight: 700;
  font-style: normal;
  src: url('../assets/fonts/Inter-Bold.woff') format('woff'),
    url('../assets/fonts/Inter-Bold.woff2') format('woff2');
}
