.serviceConfirmationWrapper{
    display: flex;
    flex-direction: column;
    width: var(--p320);
    .text{
        margin-bottom: var(--p8);
        line-height:var(--line-height-base);
    }
    .desc{
        color: var(--theme-text-color-tertiary);
        line-height:var(--line-height-base);
    }
    .footer{
        padding: var(--p20) var(--p20) 0 var(--p20);
        margin: var(--p20) calc(var(--p20) * -1) 0;
        border-top: var(--p1) solid var(--theme-border-color-light-divider);
        display: flex;
        .cancelBtn{
            margin-right: var(--p12);
            flex: 1;
        }
        .exportBtn{
            flex: 1;
        }
    }
}