@import 'common/theme/responsive.module.scss';
.noStoreFoundWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: var(--theme-background-color-primary);
  .innerWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--p48) var(--p36);
    background: var(--theme-color-primary);
    border-radius: var(--radius-md);
    box-shadow: 0px 2px 4px -4px rgba(20, 20, 20, 0.04), 0px 4px 8px -4px rgba(20, 20, 20, 0.02);
    @include mobile{
      padding: var(--p36) var(--p32);
      margin: 0 var(--p24);
    }
    .noStateImg{
      margin-bottom: var(--p32);
    }
    .message{
      margin-bottom: var(--p12);
    }
    .description{
      margin-bottom: var(--p32);
      color: var(--theme-text-color-tertiary);
    }
    .link{
      color: var(--theme-color-accent);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}