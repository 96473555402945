.buttonBase {
  font-weight: var(--font-weight-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all var(--transition-1);
}

.disabled {
  cursor: default;
}

.solid {
  &.primary {
    color: var(--theme-text-color-inverse-primary);
    background: var(--theme-background-color-accent);
    @media (hover: hover) {
      &:hover {
        background-color: var(--theme-background-color-accent-dark);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-accent-light);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-accent-light);
        }
      }
    }
    &.selected {
      background-color: var(--theme-background-color-accent-light);
      color: var(--theme-text-color-accent);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-accent-light);
          color: var(--theme-text-color-accent);
        }
      }
    }
  }

  &.inverted {
    color: var(--theme-text-color-primary);
    background-color: var(--theme-background-color-tertiary);
    @media (hover: hover) {
      &:hover {
        background-color: var(--theme-background-color-primary);
        color: var(--theme-text-color-primary);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-tertiary);
      color: var(--color-grey300);
    }
  }

  &.secondary {
    color: var(--theme-text-color-inverse-primary);
    background: var(--theme-background-color-inverse-primary);
    @media (hover: hover) {
      &:hover {
        background: var(--theme-background-color-inverse-tertiary);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-disabled);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-disabled);
        }
      }
    }
  }

  &.negative {
    color: var(--theme-text-color-inverse-primary);
    background: var(--theme-background-color-negative);
    @media (hover: hover) {
      &:hover {
        background: var(--theme-background-color-inverse-tertiary);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-disabled);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-disabled);
        }
      }
    }
  }
}

.outline {
  border: var(--border-sm) solid;
  &.primary {
    color: var(--theme-text-color-accent);
    border-color: var(--theme-border-color-accent);
    @media (hover: hover) {
      &:hover {
        color: var(--theme-text-color-inverse-primary);
        background: var(--theme-background-color-accent);
      }
    }
    &.disabled {
      opacity: var(--opacity-1);
      @media (hover: hover) {
        &:hover {
          color: var(--theme-primary-color);
          background: transparent;
        }
      }
    }
  }

  &.inverted {
    color: var(--theme-color-text-default);
    background-color: var(--theme-background-color);
    @media (hover: hover) {
      &:hover {
        background-color: var(--theme-text-color-tertiary);
        color: var(--theme-background-color);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color);
      color: var(--button-inverted-disabled-color);
    }
  }
  &.secondary {
    color: var(--theme-text-color-primary);
    border-color: var(--theme-border-color-disabled);
    @media (hover: hover) {
      &:hover {
        color: var(--theme-text-color-inverse-primary);
        background: var(--theme-background-color-inverse-primary);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-disabled);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-disabled);
        }
      }
    }
  }
  &.tertiary {
    color: var(--theme-text-color-primary);
    border-color: var(--theme-border-color-primary-light);
    @media (hover: hover) {
      &:hover {
        color: var(--theme-text-color-primary);
        background: var(--theme-border-color-primary-light);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color-disabled);
      @media (hover: hover) {
        &:hover {
          background-color: var(--theme-background-color-disabled);
        }
      }
    }
  }
}

.ghost {
  &.primary {
    color: var(--theme-text-color-accent);
    background: transparent;
    border: 1px solid transparent;
    @media (hover: hover) {
      &:hover {
        background: var(--theme-background-color-accent-light);
        border: 1px solid var(--theme-border-color-accent-light);
      }
    }
    &.disabled {
      color: var(--theme-color-color-disabled);
      background: var(--theme-background-color-disabled);
    }
  }

  &.inverted {
    color: var(--theme-color-text-default);
    background-color: var(--theme-background-color);
    @media (hover: hover) {
      &:hover {
        background-color: var(--theme-text-color-tertiary);
        color: var(--theme-background-color);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color);
      color: var(--button-inverted-disabled-color);
    }
  }
}

.text {
  &.primary {
    color: var(--theme-text-color-accent);
  }
  &.secondary {
    color: var(--theme-text-color-primary);
  }
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
  &.disabled {
    color: var(--theme-text-primary-color-disabled);
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.inverted {
    color: var(--theme-color-text-default);
    background-color: var(--theme-background-color);
    @media (hover: hover) {
      &:hover {
        background-color: var(--theme-text-color-tertiary);
        color: var(--theme-background-color);
      }
    }
    &.disabled {
      background-color: var(--theme-background-color);
      color: var(--button-inverted-disabled-color);
    }
  }
}

.fluid {
  width: 100%;
}

.xs {
  font-size: var(--font-size-xs);
  padding: var(--p4) var(--p8);
  border-radius: var(--radius-base);
}

.sm {
  font-size: var(--font-size-base);
  padding: var(--p8) var(--p12);
  border-radius: var(--radius-md);
}

.md {
  font-size: var(--font-size-sm);
  padding: var(--p12) var(--p16);
  border-radius: var(--radius-md);
}

.lg {
  font-size: var(--font-size-lg);
  padding: var(--p12) var(--p20);
  border-radius: var(--radius-md);
}
