@import 'common/theme/responsive.module.scss';

.modalContainer {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-high);
  opacity: 1;
  position: fixed;
  background: var(--theme-background-color-primary-overlay-dark);
}

.modalWrapper {
  height: auto;
  padding: var(--p20);
  position: fixed;
  background: var(--theme-background-color-tertiary);
  border-radius: var(--radius-md);

  &.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: auto;
  }

  &.upperRight {
    top: var(--p8);
    right: var(--p8);
  }

  &.noPadding {
    padding: 0;
  }

  @include mobile {
    width: 100%;
    height: 100%;
    z-index: var(--z-low);
    position: absolute;
    border-radius: 0;
  }
}

.modalHeader {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: var(--p20);
  justify-content: space-between;
}

.headerTitleContent {
  width: 100%;
  padding-right: var(--p28);
}

.closeButton {
  right: var(--p1);
  position: absolute;
  & img {
    display: block;
  }
}
