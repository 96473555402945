.shopifyGrantWrapper {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-very-high);
  background-color: #f6f6f7;
  .content{
    min-width: 55rem;
    display: flex;
    margin: auto;
    justify-content: center;
  }
}