$tooltip-arrow-rotation-vertical: -135deg;
$tooltip-arrow-rotation-horizontal: -45deg;

.toolTip {
  position: fixed;
  z-index: var(--z-very-high);
  white-space: nowrap;
  backdrop-filter: blur(0.75rem);
  pointer-events: none;

  font-size: var(--font-size-xs);
  padding: var(--p8) var(--p12);
  border-radius: var(--radius-md);
  line-height: var(--line-height-sm);
  text-transform: uppercase;
  max-width: 40%;
  white-space: initial;

  transition: var(--transition-default);

  &:before {
    content: '';
    left: 50%;
    border: solid transparent;
    position: fixed;
    z-index: -1;
    border-width: var(--p8);
  }
}

.top {
  &:before {
    bottom: calc(-1 * var(--p4));
    left: 50%;
    clear: both;
    border-top-color: var(--theme-border-color-highlight);
    border-left-color: var(--theme-border-color-highlight);
    border-radius: var(--radius-md);
    transform: translateX(-50%) rotate($tooltip-arrow-rotation-vertical);
  }
}

.right {
  &:before {
    right: auto;
    left: calc(var(--p4) * -1.2);
    top: 50%;
    clear: both;
    border-left-color: var(--theme-border-color-highlight);
    border-top-color: var(--theme-border-color-highlight);
    border-radius: var(--radius-md);
    transform: translateY(-50%) rotate($tooltip-arrow-rotation-horizontal);
  }
}

.bottom {
  &:before {
    top: calc(-1 * var(--p4));
    left: 50%;
    clear: both;
    border-bottom-color: var(--theme-border-color-highlight);
    border-right-color: var(--theme-border-color-highlight);
    border-radius: var(--radius-md);
    transform: translateX(-50%) rotate($tooltip-arrow-rotation-vertical);
  }
}

.left {
  &:before {
    left: auto;
    right: calc(var(--p4) * -1.2);
    top: 50%;
    clear: both;
    border-radius: var(--radius-md);
    border-bottom-color: var(--theme-border-color-highlight);
    border-right-color: var(--theme-border-color-highlight);
    transform: translateY(-50%) rotate($tooltip-arrow-rotation-horizontal);
  }
}

.default {
  background: var(--theme-border-color-highlight);
  color: var(--theme-text-color-inverse-primary);

  &:before {
    border-color: var(--theme-border-color-highlight);
  }
}

.success {
  background-color: var(--theme-background-color-positive);
  color: var(--theme-text-color-inverse-primary);

  &:before {
    border-color: var(--theme-background-color-positive);
  }
}

.warning {
  background-color: var(--theme-background-color-warning);
  color: var(--theme-text-color-inverse-primary);

  &:before {
    border-color: var(--theme-background-color-warning);
  }
}

.error {
  background-color: var(--theme-background-color-negative);
  color: var(--theme-text-color-inverse-primary);

  &:before {
    border-color: var(--theme-background-color-negative);
  }
}

.secondary {
  background-color: var(--theme-background-color-secondary);

  &:before {
    border-color: var(--theme-background-color-secondary);
  }
}
