.errorMessage{
    width: 100%;
    padding: var(--p6);
    font-size: var(--font-size-xs);
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255, 255, 0, 0.801);
    cursor: pointer;
    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}