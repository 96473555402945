@import 'common/theme/responsive.module.scss';

.wrapper {
  --open-top: 2.5rem;
  --collapsed-top: -2.5rem;
  --open-bottom: unset;
  --collapsed-bottom: unset;

  position: fixed;
  z-index: var(--z-very-high);
  top: -2.5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mobile {
    --open-bottom: 2.5rem;
    --collapsed-bottom: -2.5rem;
    --open-top: unset;
    --collapsed-top: unset;

    top: unset;
    bottom: -2.5rem;

    width: fit-content;
    max-width: 90%;
  }
}
