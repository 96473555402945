.toastBase {
  border-radius: var(--radius-md);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  padding: var(--p12);
  margin-bottom: var(--p4);
  box-shadow: 0 0.5rem 0.75rem -0.375rem rgba(20, 20, 20, 0.04),
    0 1rem 2rem -0.25rem rgba(20, 20, 20, 0.02);

  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;

  &.isClickable {
    cursor: pointer;
  }

  .leftIconWrapper {
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--p8);
  }

  .rightIconWrapper {
    margin-left: var(--p16);
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .rightIconWrapperWithTitle {
    margin-left: var(--p16);
    cursor: pointer;
  }

  .toastContentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      font-weight: var(--font-weight-semibold);
      font-size: var(--font-size-base);
      line-height: var(--font-size-xl);
      align-self: stretch;
    }

    .body {
      display: flex;
      align-items: baseline;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: var(--p16);
    &.iconButton {
      margin-left: var(--p8);
    }
  }
}

.default {
  background-color: var(--theme-background-color-inverse-primary);
  color: var(--theme-color-primary);
}

.success {
  background-color: var(--theme-background-color-positive);
  color: var(--theme-text-color-inverse-primary);
}

.error {
  background-color: var(--theme-background-color-negative);
  color: var(--theme-text-color-inverse-primary);
}
