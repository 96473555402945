.shopifyStoreMatcherWrapper {
  min-width: 45rem;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow: auto;
  .content{
    display: flex;
    flex-direction: row;
    .arrow{
      display: flex;
      margin-top: var(--p16);
    }
    .disabled{
      opacity: var(--opacity-3);
      pointer-events: none;
    }
  }
}