@import 'common/theme/responsive.module.scss';

.wrapper {
  position: relative;
  border-radius: var(--radius-md);
  & .imageCover {
    width: 100%;
    max-height: 90vh;
    height: auto;
    object-fit: cover;
    display: block;
    border-radius: var(--radius-md);
  }

  & .videoCover {
    min-width: 50rem;
    max-height: 90vh;
    width: 100%;
    height: auto;
    display: block;
    border-radius: var(--radius-md);
    border: 0;
    outline: 0;

    @include mobile {
      min-width: auto;
    }
  }

  & .closeButton {
    position: absolute;
    top: var(--p12);
    right: var(--p12);
    border-radius: var(--radius-md);
    z-index: var(--z-medium);
  }

  .leftButton {
    position: absolute;
    top: 50%;
    left: var(--p20);
    border-radius: var(--radius-md);
    z-index: var(--z-medium);
  }

  .rightButton {
    position: absolute;
    top: 50%;
    right: var(--p20);
    border-radius: var(--radius-md);
    z-index: var(--z-medium);
  }

  .audioWrapper {
    padding: var(--p32) var(--p24);
  }
  .downloadButtonWrapper {
    padding: var(--p32) var(--p24);
    min-width: var(--p288);
    display: flex;
    justify-content: center;
  }
}
