.loadingWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.dotsList {
  display: flex;
}

.dot {
  border-radius: var(--radius-full);

  background: var(--color-overlay-light-50);
  &.invert{
    background: var(--color-white);
  }
  animation: dotAnimation 1s infinite ease-in-out both;
  &.sm {
    padding: var(--p4);
    margin: 0 var(--p4);
  }
  &.md {
    padding: var(--p8);
    margin: 0 var(--p8);
  }

  &.xs {
    padding: var(--p1);
    margin: 0 var(--p1);
  }
}

.dot:nth-child(1) {
  animation-delay: -0.48s;
}

.dot:nth-child(2) {
  animation-delay: -0.32s;
}

.dot:nth-child(3) {
  animation-delay: -0.16s;
}

@keyframes dotAnimation {
  0%,
  80%,
  100% {
    transform: scale(0.7);
  }
  40% {
    transform: scale(1);
    background: var(--theme-background-color-inverse-primary);
  }
}

.circleContent {
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
