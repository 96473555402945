@import './common/theme/stylevars.module.scss';
@import './common/theme/reset.module.scss';
@import './common/theme/font.module.scss';
@import './common/theme/responsive.module.scss';

html {
  font-size: 16px;
  @include small {
    font-size: 13px;
  }
}

body {
  font-family: var(--font-family);
  font-size: 100%;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  color: var(--theme-text-color-primary);
  font-size: var(--font-size-base);
}
