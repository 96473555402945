.wrapper {
  width: var(--p288);
  height: 100%;
  margin: auto;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .heading {
    margin: var(--p8) 0px;
    font-weight: var(--font-weight-medium);
  }

  .description {
    color: var(--theme-text-color-inverse-tertiary);
    font-weight: var(--font-weight-regular);
    line-height: var(--line-height-base);
  }
}
