.criticalAlertWrapper {
  display: flex;
  background-color: var(--theme-background-color-negative);
  color: var(--theme-text-color-inverse-primary);
  justify-content: center;
  &.warning{
    background-color: var(--theme-background-color-warning-dark);
  }
  .content{
    padding: var(--p16);
    .text{

    }
    .btn{
      text-decoration: underline;
      margin-left: var(--p12);
      cursor: pointer;
    }
  }
}