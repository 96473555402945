@import './responsive.module.scss';

:root {
  /*Style guide variables */

  --mobile-width: #{$mobile-width};

  /* Colors */
  --color-transparent: transparent;
  --color-black: #000;
  --color-white: #fff;
  --google-blue: #4285F4;
  --facebook-blue: #1877f2;
  /* Overlay dark */
  --color-overlay-dark: rgba(0, 0, 0, 0.05);
  --color-overlay-dark-50: rgba(0, 0, 0, 0.8);
  --color-overlay-dark-100: rgba(0, 0, 0, 0.3);
  /* Overlay Light */
  --color-overlay-light-50: rgba(255, 255, 255, 0.12);
  --color-overlay-light-100: rgba(255, 255, 255, 0.2);
  --color-overlay-light-200: rgba(232, 232, 232, 0.5);
  /* Grey */
  --color-grey50: #141414;
  --color-grey100: #1f1f1f;
  --color-grey200: #333333;
  --color-grey300: #545454;
  --color-grey400: #757575;
  --color-grey500: #afafaf;
  --color-grey600: #cbcbcb;
  --color-grey700: #e2e2e2;
  --color-grey800: #e8e8e8;
  --color-grey900: #f5f5f5;
  /* Red */
  --color-red50: #330f0a;
  --color-red100: #5a0a00;
  --color-red200: #870f00;
  --color-red300: #ab1300;
  --color-red400: #e11900;
  --color-red500: #e85c4a;
  --color-red600: #f1998e;
  --color-red700: #fed7d2;
  --color-red800: #ffefed;
  /* Orange */
  --color-orange50: #331a12;
  --color-orange100: #672a16;
  --color-orange200: #9a3f21;
  --color-orange300: #c14f29;
  --color-orange400: #ff6937;
  --color-orange500: #fa9269;
  --color-orange600: #fabda5;
  --color-orange700: #f7dfd7;
  --color-orange800: #fff3ef;
  /* Yellow */
  --color-yellow50: #332a1a;
  --color-yellow100: #674d1b;
  --color-yellow200: #997328;
  --color-yellow300: #bc8b2c;
  --color-yellow400: #ffc043;
  --color-yellow500: #ffcf70;
  --color-yellow600: #ffe3ac;
  --color-yellow700: #fff2d9;
  --color-yellow800: #fffaf0;
  /* Green */
  --color-green50: #0f261c;
  --color-green100: #10462d;
  --color-green200: #03582f;
  --color-green300: #03703c;
  --color-green400: #05944f;
  --color-green500: #06c167;
  --color-green600: #66d19e;
  --color-green700: #addec9;
  --color-green800: #e6f2ed;
  /* Blue */
  --color-blue50: #080b26;
  --color-blue100: #050c4d;
  --color-blue200: #081270;
  --color-blue300: #0a1899;
  --color-blue400: #0e1fc1;
  --color-blue500: #535fcf;
  --color-blue600: #949ce3;
  --color-blue700: #d2d7f0;
  --color-blue800: #ebedfa;
  /* Cyan */
  --color-cyan50: #0f1c33;
  --color-cyan100: #dce5f5;
  --color-cyan200: #174291;
  --color-cyan300: #1e54b7;
  --color-cyan400: #276ef1;
  --color-cyan500: #1e54b7;
  --color-cyan600: #a0bff8;
  --color-cyan700: #d4e2fc;
  --color-cyan800: #eff3fe;
  /* Purple */
  --color-purple50: #231c33;
  --color-purple100: #2e224c;
  --color-purple200: #453473;
  --color-purple300: #574191;
  --color-purple400: #7356bf;
  --color-purple500: #957fce;
  --color-purple600: #c1b5e3;
  --color-purple700: #e3ddf2;
  --color-purple800: #f4f1fa;

  /* Typography */
  --font-family: 'Inter', sans-serif;
  /* Font size*/
  --font-size-xxs: 0.5rem; // 8px
  --font-size-xss: 0.688rem; // 11px
  --font-size-xs: 0.75rem; // 12px
  --font-size-base: 0.875rem; // 14px
  --font-size-sm: 1rem; // 16px
  --font-size-lg: 1.125rem; // 18px
  --font-size-xl: 1.25rem; // 20px
  --font-size-2xl: 1.5rem; // 24px
  --font-size-3xl: 1.875rem; // 30px
  --font-size-4xl: 2.25rem; // 36px
  --font-size-5xl: 2.5rem; // 40px
  --font-size-6xl: 3rem; // 48px
  /* Font Weight*/
  --font-weight-light: 300;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  /* Line Height*/
  --line-height-xxs: 0.75rem;
  --line-height-xs: 1rem;
  --line-height-base: 1.25rem;
  --line-height-sm: 1.375rem;
  --line-height-lg: 1.625rem;
  --line-height-xl: 1.75rem;
  --line-height-2xl: 2rem;
  --line-height-3xl: 2.25rem;
  --line-height-4xl: 2.75rem;
  --line-height-5xl: 3rem;
  --line-height-5xl: 3.75rem;

  /* Spacing */
  --p1: 0.0625rem; // 1px
  --p4: 0.25rem; // 4px
  --p6: 0.375rem; // 6px
  --p8: 0.5rem; // 8px
  --p12: 0.75rem; // 12px
  --p16: 1rem; // 16px
  --p20: 1.25rem; // 20px
  --p24: 1.5rem; // 24px
  --p28: 1.75rem; // 28px
  --p32: 2rem; // 32px
  --p36: 2.25rem; // 36px
  --p40: 2.5rem; // 40px
  --p48: 3rem; // 48px
  --p56: 3.5rem; // 56px
  --p64: 4rem; // 64px
  --p80: 5rem; // 80px
  --p96: 6rem; // 96px
  --p112: 7rem; // 112px
  --p128: 8rem; // 128px
  --p144: 9rem; // 144px
  --p160: 10rem; // 160px
  --p176: 11em; // 176px
  --p192: 12rem; // 192px
  --p224: 14rem; // 224px
  --p240: 15rem; // 240px
  --p256: 16rem; // 256px
  --p288: 18rem; // 288px
  --p320: 20rem; // 320px
  --p384: 24rem; // 384px
  --p400: 25rem; // 400px
  --p480: 30rem; // 480px
  --p520: 32.5rem; // 520px
  --p540: 33.75rem; // 540px

  /* Spacing backup */
  // --space-0: 0.375rem; // 6px
  // --space-1: 0.063rem; // 1.008px
  // --space-2: 0.25rem; // 4px
  // --space-3: 0.5rem; // 8px
  // --space-4: 0.75rem; // 12px
  // --space-5: 1rem; // 16px
  // --space-6: 1.25rem; // 20px
  // --space-7: 1.5rem; // 24px
  // --space-8: 1.75rem; // 28px
  // --space-9: 2rem; // 32px
  // --space-10: 2.25rem; // 36px
  // --space-11: 2.5rem; // 40px
  // --space-12: 3rem; // 48px
  // --space-13: 3.5rem; // 56px
  // --space-14: 4rem; // 64px
  // --space-15: 5rem; // 80px
  // --space-16: 6rem; // 96px
  // --space-17: 7rem; // 112px
  // --space-18: 8rem; // 128px
  // --space-19: 9rem; // 144px
  // --space-20: 10rem; // 160px
  // --space-21: 11em; // 176px
  // --space-22: 12rem; // 192px
  // --space-23: 14rem; // 224px
  // --space-24: 15rem; // 240px
  // --space-25: 16rem; // 256px
  // --space-26: 18rem; // 288px
  // --space-27: 20rem; // 320px
  // --space-28: 24rem; // 384px
  // --space-29: 25rem; // 400px
  // --space-30: 30rem; // 480px
  // --space-31: 32.5rem; // 520px
  // --space-32: 33.75rem; // 540px

  /* Radius */
  --radius-sm: 0.125rem; // 2px
  --radius-base: 0.25rem; // 4px
  --radius-md: 0.375rem; // 6px
  --radius-lg: 0.5rem; // 8px
  --radius-xl: 0.75rem; // 12px
  --radius-2xl: 1rem;
  --radius-3xl: 1.5rem;
  --radius-4xl: 6.25rem;

  --radius-full: 100%;

  /* Border */
  --border-sm: 0.063rem; // 1.008px
  --border-md: 0.125rem; // 2px
  --border-lg: 0.75rem; // 12px

  /* Transitions */
  --transition-default: 0.2s ease;
  --transition-1: 0.2s ease;
  --transition-2: 0.5s ease;

  /* Opacity */
  --opacity-1: 0.2;
  --opacity-2: 0.4;
  --opacity-3: 0.6;
  --opacity-4: 0.8;

  /* z-index*/
  --z-very-high: 11;
  --z-high: 10;
  --z-medium: 9;
  --z-low: 8;
  --z-ultra-low: 1;


  /* Shadow */
  --shadow-xs: 0 0.063rem 0.25rem rgba(0, 0, 0, 0.04);
  --shadow-sm: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.08);
  --shadow-md: 0 0.5rem 1rem rgba(0, 0, 0, 0.08);
  --shadow-lg: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.08);
  --shadow-xl: 0 1rem 2rem rgba(0, 0, 0, 0.08);
  --shadow-2xl: 0 1.5rem 3rem rgba(0, 0, 0, 0.08);
  --focus-shadow: 0px 0px 0px 0.125rem rgba(255, 105, 55, 0.16);
  --shadow-spread: 0px 4px 8px -4px rgba(20, 20, 20, 0.04),
    0px 8px 16px -4px rgba(20, 20, 20, 0.02);
}
