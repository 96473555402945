.link {
  color: var(--theme-text-color-accent);
  word-wrap: break-word;
}

.highlight {
  color: var(--theme-text-color-accent);
  font-weight: var(--font-weight-bold);
}

.readMoreButton {
  text-decoration: underline;
  padding-top: var(--p6);
}

.xxs {
  font-size: var(--font-size-xxs);
}

.xss {
  font-size: var(--font-size-xss);
}

.xs {
  font-size: var(--font-size-xs);
}

.base {
  font-size: var(--font-size-base);
}

.sm {
  font-size: var(--font-size-sm);
}

.lg {
  font-size: var(--font-size-lg);
}

.xl {
  font-size: var(--font-size-xl);
}

.xl2 {
  font-size: var(--font-size-2xl);
}

.xl3 {
  font-size: var(--font-size-3xl);
}

.xl4 {
  font-size: var(--font-size-4xl);
}

.xl5 {
  font-size: var(--font-size-5xl);
}

.xl6 {
  font-size: var(--font-size-6xl);
}

.weight-300 {
  font-weight: 300;
}

.weight-400 {
  font-weight: 400;
}

.weight-500 {
  font-weight: 500;
}

.weight-600 {
  font-weight: 600;
}

.weight-700 {
  font-weight: 700;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.5;
  max-width: 100%;
}
